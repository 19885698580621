import * as React from "react"
import Layout from "../views/layouts/layout"
import {graphql} from "gatsby";
import CategoryDefault from "../views/templates/template.category.default";


export default function CategoryController(props) {

	const posts = props.data.posts.nodes
	const category = props.data.category
	const context = props.pageContext



	return (
		<Layout node={category}>
			<CategoryDefault category={category} posts={posts} context={context}/>
		</Layout>
	)
}



export const pageQuery = (graphql`

  query  CategoryController_query($id: String!){
	  category: wpCategory(id: { eq: $id }) {
	    uri
	    slug
	    name
	    language {
          code
        }
	  }
	  
	  posts: allWpPost(
	    filter: {terms: {nodes: {elemMatch: {id: {eq: $id}}}}},
	  	sort: {date: DESC}
	    ) {
	    nodes {
	      id
	      title
	      excerpt
	      uri
	      terms {
	        nodes {
	          id
	        }
	      }
	    }
	  }
	}

`)