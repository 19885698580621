import * as React from "react"
import { Link } from "gatsby"
import { Box } from "@chakra-ui/react"
import PostBox from "../components/page/blog/postBox";
import HeroShort from "../components/heroShort/hero";

export default function html(props) {

	const category = props.category
	const currentPage = props.context.page??1
	const postPerPage = process.env.GATSBY_POST_PER_PAGE
	const start = (currentPage-1)*postPerPage
	const end = currentPage*postPerPage
	const page = props.page

	const postCount = props.posts.length
	const pageCount = Math.ceil(postCount/postPerPage)

	const posts = props.posts.slice(start,end)

	const pages=[];
	let uri =''
	for (var i = 1; i <= pageCount; i++) {
		if(i === 1) uri = category.uri
		else uri = category.uri + i + '/'

		let html = '<b>'+i+'</b>'
		pages.push(<Link key={i} to={uri} dangerouslySetInnerHTML={{ __html: (currentPage===i)?html:i }}></Link>)
	}

	return (
		<>
			<HeroShort page={page}/>
			<Box p={{ base: '20px 5% 20px 5%', md:'40px 10% 40px 10%',lg:'0 15% 50px 15%'}}>
				<h1>{category.name}</h1>
				<hr/>
				Strony:
				{pages}
				<hr/>
				{posts.map((node,i) => (
					<PostBox key={node.id} node={node} i={i}/>
				))}
			</Box>
		</>
	)
}

